var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bRBLYsUUJSB9zAwzfLO1N"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { SENTRY_DSN } from "@/sentry";
import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    // Add browser profiling integration to the list of integrations
    new Sentry.BrowserProfilingIntegration(),
  ],
  profilesSampleRate: 1.0,
});
