import type { ProSettings } from "@ant-design/pro-components";
import { PageContainer, ProCard, ProLayout } from "@ant-design/pro-components";
import { ProConfigProvider } from "@ant-design/pro-provider";
import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { useEffect, useState } from "react";

import {
  HomeOutlined,
  LogoutOutlined,
  SettingOutlined,
  ProjectOutlined,
  SketchOutlined,
} from "@ant-design/icons";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import logoPng from "../../public/logo.png";
import enUS from "antd/lib/locale/en_US";
import { ConfigProvider } from "antd";

const defaultProps = {
  route: {
    path: "/",
    routes: [
      {
        path: "/",
        name: "Homepage",
        icon: <HomeOutlined />,
      },
      // {
      //   path: "/dashboard",
      //   name: "dashboard",
      //   icon: <DashboardOutlined />,
      // },
      {
        path: "/account",
        name: "Account",
        icon: <LogoutOutlined />,
      },
      {
        path: "/manage",
        name: "Form Management",
        icon: <SettingOutlined />,
      },
      {
        path: "/referral",
        name: "Referral System",
        icon: <ProjectOutlined />,
      },

      {
        path: "/accessTransfer",
        name: "Access Transfer",
        icon: <SketchOutlined />,
      },
    ],
  },
  location: {
    pathname: "/",
  },
  logo: <img src={logoPng.src} />,
  title: "Kaito Operation Platform System",
};
export default function App({ Component, pageProps, session }: AppProps & { session: Session }) {
  const [settings] = useState<Partial<ProSettings> | undefined>({
    layout: "side",
    navTheme: "realDark",
  });
  const router = useRouter();
  // pro-components doesn't support ssr, so disable ssr;
  const [showChild, setShowChild] = useState(false);
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }
  return (
    <ConfigProvider
      locale={enUS}
      theme={{
        components: {
          Tabs: {
            itemSelectedColor: "#fff",
          },
        },
      }}
    >
      <ProConfigProvider hashed={false} autoClearCache>
        <ProLayout
          {...defaultProps}
          location={{
            pathname: router.asPath,
          }}
          siderWidth={256}
          menuItemRender={(item, dom) => (
            <div
              onClick={() => {
                if (item.isUrl) {
                  window.open(item.path);
                } else {
                  router.push(item.path || "/welcome");
                }
              }}
            >
              {dom}
            </div>
          )}
          {...settings}
        >
          <PageContainer>
            <ProCard
              style={{
                minHeight: 800,
              }}
            >
              <SessionProvider>
                <Component {...pageProps} />
              </SessionProvider>
            </ProCard>
          </PageContainer>
        </ProLayout>
      </ProConfigProvider>
    </ConfigProvider>
  );
}
